import { Helper } from "@favo/utils/helper";
import { Subject } from "rxjs";
export class SessionTracker {

    public onExpired: Subject<boolean> = new Subject();
    private worker: Worker;
    private isStarted: boolean = false;

    constructor() {
        this.worker = new Worker('/token.worker.js');
    }

    public async startTracking(accessToken: string | undefined) {

        if (this.isStarted === true) return;

        if (accessToken === undefined) return;


        try {

            // Start the worker
            const expDate = Helper.getTokenExpiry();
            this.worker.postMessage({ check: true, exp: expDate });

            this.isStarted = true;

            this.worker.onmessage = (event: MessageEvent) => {

                if (event.data) {
                    this.onExpired.next(true);
                    console.log("Session expired")
                    return;
                }

                this.onExpired.next(false);
            };
        } catch {
            return true;
        }
    }

    public async stopTracking() {
        // Stop the worker
        if (this.isStarted === true) {
            this.worker.terminate();
            this.isStarted = false
        }
    }

}