import { GlobalContext, IGlobalContextType } from '@favo/contexts/global/main.context';
import { AIModelService } from '@favo/services/ai.model.service';
import { DiffusionClientService } from '@favo/services/d.client.service';
import { PromptService } from '@favo/services/prompt.service';
import { PurchaseService } from '@favo/services/purchase.service';
import { Helper } from '@favo/utils/helper';
import { SessionTracker } from '@favo/workers/session-tracker';
import React from 'react';
import { Subject } from 'rxjs';
import { UserService } from '../../services/user.service';

export interface GlobalContextProviderProps extends React.PropsWithChildren<{}> {
}

class GlobalContextProviderComponent extends React.Component<GlobalContextProviderProps> {

    private _sessionTracker = new SessionTracker();

    componentDidMount(): void {

        this.state.onSignedIn.subscribe((response) => {
            this.state.services.userService.login(response.credential!, response.clientId!);
        });

        this._trackSession();
    }


    private _trackSession() {

        this._sessionTracker.startTracking(Helper.getToken());

        this._sessionTracker.onExpired.subscribe((isExpired) => {
            if (isExpired) {
                this.setState({ isLoggedIn: false });
                this._sessionTracker.stopTracking();

                // redirect to home page
                window.location.href = '/';
            }
        });
    }

    public state: IGlobalContextType = {
        loading: false,
        isLoggedIn: false,
        setIsLoggedIn: (isLoggedIn: boolean) => {
            this.setState({ isLoggedIn });
        },
        setLoader: (loading: boolean) => {
            this.setState({ loading });
        },
        onSignedIn: new Subject(),
        services: {
            userService: new UserService(),
            modelService: new AIModelService(),
            purchaseService: new PurchaseService(),
            diffusionClientService: new DiffusionClientService(),
            promptService: new PromptService(),
        }
    };

    public render(): JSX.Element {
        return (
            <GlobalContext.Provider value={this.state}>
                {this.props.children}
            </GlobalContext.Provider >
        );
    }
}

export const GlobalContextProvider = GlobalContextProviderComponent;