import { ModelImageOption } from "@favo/models/image.option.model";
import { Sampler } from '@favo/models/sampler.model';
import { Helper } from "@favo/utils/helper";
import axios from "axios";
import { Observable } from "rxjs";


interface GenerationOutput {
    images: string[],
    promptId: string,
}

export class DiffusionClientService {

    public getSamplers(): Observable<Sampler[]> {
        return new Observable<Sampler[]>(observer => {
            const token = Helper.getToken();

            axios.get(`${process.env.REACT_APP_D_CLIENT_URL}/sdapi/v1/samplers`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {

                if (response.status === 200 && response.data.length > 0) {
                    const samplers = response.data.map((sampler: any) => {
                        return new Sampler(
                            sampler.name,
                            sampler.aliases,
                            sampler.options
                        );
                    });

                    observer.next(samplers);
                    return;
                }

                observer.next([]);

            }).catch(error => {
                observer.error(error);
                observer.complete();
            });

        });
    }

    public generateImage(option: ModelImageOption): Observable<GenerationOutput | null> {
        return new Observable<GenerationOutput | null>(observer => {
            const token = Helper.getToken();

            axios.post(`${process.env.REACT_APP_D_CLIENT_URL}/sdapi/v1/txt2img`, {
                prompt: option.prompt,
                negative_prompt: option.negativePrompt,
                sampler_name: option.sampler,
                restore_faces: option.restoreFaces,
                steps: option.samplingSteps,
                width: option.width,
                height: option.height,
                cfg_scale: option.cfg,
                seed: option.seed === 'Random' ? -1 : option.seed,
                enable_controlnet: option.enableControlnet,
                module: option.module,
                model: option.model,
                weight: option.weight,
                image: option.image,
                scribble_mode: option.scribble,
                resize_mode: option.resizeMode,
                rgbbgr_mode: option.rgbbgrMode,
            }, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "ModelId": `${option.modelId}`
                }
            }).then(response => {

                if (response.status === 200 && response.data.images.length > 0) {
                    const images = response.data.images!;
                    const promptId = response.data.prompt_id!;

                    observer.next({images, promptId});
                    return;
                }

                observer.next(null);

            }).catch(error => {
                observer.error(error);
                observer.complete();
            });

        });
    }

}