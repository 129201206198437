export class Vote {
    upvotes: number;
    downvotes: number;

    constructor(upvotes: number, downvotes: number) {

        this.upvotes = upvotes;
        this.downvotes = downvotes;
    }
    
}