import React, { Component } from 'react';
import styles from '@favo/components/home/styles/beta-closing.module.scss'; // ensure you have a corresponding CSS module for styles

class BetaClosing extends Component {
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <h1>Thanks for participating in our beta!</h1>
                </div>

                <div className={styles.body}>
                    <p>
                        Your contribution has been invaluable in shaping the trajectory of Favo. The insights we have gathered from your experiences have played a crucial role in helping us identify opportunities for improvement and innovation.
                    </p>
                    <p>
                        As we exit our beta phase and embark on this exciting new chapter, we remain committed to our mission of building a vibrant community of AI art enthusiasts.
                    </p>
                </div>

                <div className={styles.footer}>
                    <p>For any additional questions please contact <a href="mailto:info@favo.ai">info@favo.ai</a>.</p>
                </div>
            </div>
        );
    }
}

export default BetaClosing;
