import { AIModel } from "@favo/models/ai.model.model";
import { AccountStatus } from "@favo/models/account_status.model";
import { User } from "@favo/models/user.model";
import { Helper } from "@favo/utils/helper";
import axios from "axios";
import { Subject, Observable } from 'rxjs';

export class UserService {

    public onUserLoggedIn: Subject<User> = new Subject<User>();

    public isLoggedIn(): boolean {
        return Helper.getToken() !== undefined;
    }

    public async login(credential: string, clientId: string): Promise<User> {
        // use axios to post user to server
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {}, {
            headers: {
                "Authorization": `Bearer ${credential}`
            }
        });


        if (response.status === 200) {

            // store token in cookies with expiration date
            const dt = new Date(response.data.expiry * 1000);
            document.cookie = `token=${response.data.token}; expires=${dt.toUTCString()}; path=/`;
            document.cookie = `token_expiry=${response.data.expiry}; expires=${dt.toUTCString()}`;
            sessionStorage.setItem("user", JSON.stringify(response.data.user));
        }

        // map response to user
        const user = new User(
            response.data.user.id,
            response.data.user.username,
            response.data.user.email,
            response.data.user.last_active,
            response.data.user.stripe_id,
            response.data.user.created_at,
            response.data.user.stripe_verified,
            response.data.user.free_tokens,
            response.data.user.purchased_tokens,
            response.data.user.subscribed,
        );

        // emit user
        this.onUserLoggedIn.next(user);

        return user;
    }

    public async logout(): Promise<any> {
        // remove token from local storage
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("user");
    }

    /**
     *  Get user from session storage
     * @returns User
     */
    public getUser(): User | null {
        // get user from local storage
        const json = sessionStorage.getItem("user");

        if (json === null)
            return null;

        return JSON.parse(json) as User;
    }

    /**
     * Get user profile from server
     * @returns User
     */
    public getUserProfile(): Observable<User> {
        return new Observable<User>(observer => {
            const credential = Helper.getToken();

            axios.get(`${process.env.REACT_APP_API_URL}/user/profile`, {
                headers: {
                    "Authorization": `Bearer ${credential}`
                }
            }).then(response => {

                // map response to user
                const user = new User(
                    response.data.id,
                    response.data.username,
                    response.data.email,
                    response.data.last_active,
                    response.data.stripe_id,
                    response.data.created_at,
                    response.data.stripe_verified,
                    response.data.free_tokens,
                    response.data.purchased_tokens,
                    response.data.subscribed,
                );

                // emit user
                observer.next(user);
            }).catch(error => {
                observer.error(error);
            });

        });

    }

    public getModels(): Observable<AIModel[]> {
        return new Observable<AIModel[]>(observer => {

            const token = Helper.getToken();

            axios.get(`${process.env.REACT_APP_API_URL}/user/listings`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(res => {
                observer.next(res.data)
            }).catch(err => {
                observer.error(err);
            });
        });
    }

    public getAccountStatus(): Observable<AccountStatus> {
        return new Observable<AccountStatus>(observer => {
            const token = Helper.getToken();
            axios.post(`${process.env.REACT_APP_API_URL}/user/account/status`, {}, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(res => {
                observer.next(res.data)
            }).catch(err => {
                observer.error(err);
            });
        });
    }

}