export class ModelImageOption {
    modelId: string;
    prompt: string;
    negativePrompt: string;
    sampler: string;
    restoreFaces: boolean = false;
    samplingSteps: number = 20;
    width: number = 500;
    height: number = 500;
    cfg: number = 7;
    seed: string = 'Random';
    enableControlnet: boolean = false;
    // TODO: abstract these options in endpoint code
    module: string = "none";
    model: string = "None";
    weight: number = 1;
    image: string = "";
    scribble: boolean = false;
    resizeMode: string = "Scale to Fit (Inner Fit)"
    rgbbgrMode: boolean = false;


    constructor(modelId: string, prompt: string, negativePrompt: string, sampler: string) {
        this.modelId = modelId;
        this.prompt = prompt;
        this.negativePrompt = negativePrompt;
        this.sampler = sampler;
    }
}