import { UserGeneration } from "@favo/models/generation.model";
import { ModelImageOption } from "@favo/models/image.option.model";
import { Helper } from "@favo/utils/helper";
import axios from "axios";
import { Buffer } from 'buffer';
import { Observable } from "rxjs";

interface PrefilledClientState {
    options: ModelImageOption;
    imageUrl: string;
    controlImageUrl: string;
    missingCheckpoints: string[];
    missingTis: string[];
    runnable: boolean;
}

export class PromptService {

    public getGeneration(promptId: string): Observable<PrefilledClientState> {
        const token = Helper.getToken();
        return new Observable<PrefilledClientState>(
            (observer) => {
                axios.get(`${process.env.REACT_APP_API_URL}/user/generation/${promptId}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    const obj = JSON.parse(Buffer.from(response.data.generation_params.metadata, 'base64').toString('utf-8'));
                    axios.post(`${process.env.REACT_APP_D_CLIENT_URL}/sdapi/v1/params/updated`, {
                        parameters: obj
                    }).then((response2) => {
                        observer.next({
                            options: this.mapToModelImageOption(response2.data, response.data.generation_params.model_id),
                            imageUrl: response.data.generation_params.image_url,
                            controlImageUrl: response.data.generation_params.control_image_url,
                            missingCheckpoints: response.data.missing_checkpoints,
                            missingTis: response.data.missing_tis,
                            runnable: response.data.runnable
                        });
                        observer.complete();
                    }).catch((error) => {
                        observer.error(error);
                        observer.complete();
                    })
                    
                    
                }).catch((error) => {
                    observer.error(error);
                    observer.complete();
                });
            }
        )
    }

    public getGenerations(): Observable<UserGeneration[]> {
        const token = Helper.getToken();
        return new Observable<UserGeneration[]>(
            (observer) => {
                axios.get(`${process.env.REACT_APP_API_URL}/user/profile/generations`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    observer.next(
                        response.data.map((r: any) => {
                            const used_tis = r.used_ti_ids.length === 0 ? [] : r.used_ti_ids.split(",");
                            return {
                                imageUrl: r.image_url,
                                promptId: r.prompt_id,
                                creationTime: new Date(Date.parse(r.output_created_at)),
                                userId: r.user_id,
                                isPublic: r.public,
                                modelId: r.model_id,
                                tis: used_tis,
                            }
                        })
                    );
                    observer.complete();
                }).catch((error) => {
                    observer.error(error);
                    observer.complete();
                });
            }
        )
    }

    public getDefaultNegativePrompt(): Observable<string> {
        return new Observable<string>(
            (observer) => {
                axios.get(`${process.env.REACT_APP_D_CLIENT_URL}/sdapi/v1/default-negative-prompt`)
                .then((response) => {
                    observer.next(
                        response.data
                    );
                    observer.complete();
                }).catch((error) => {
                    observer.error(error);
                    observer.complete();
                });
            }
        )
    }

    public saveGeneration(promptId: string, b64Image: string, b64ControlImage: string | null): Observable<boolean> {
        const token = Helper.getToken();
        return new Observable<boolean>(observer => {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/user/generate/save`,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: {
                    prompt_id: promptId,
                    image: b64Image,
                    controlnet_image: b64ControlImage,
                }
            }).then(res => {
                observer.next(true);
                observer.complete();
            }).catch(err => {
                observer.error(err);
                observer.complete();
            });
        });
    }

    public async imageUrlToBase64(url: string): Promise<string> {
        const response = await axios.get(url, { responseType: 'arraybuffer' });
        const base64 = Buffer.from(response.data).toString('base64');
        return base64;
    }

    private mapToModelImageOption(data: any, modelId: string): ModelImageOption {
        var options = new ModelImageOption(
            modelId,
            data.prompt,
            data.negative_prompt,
            data.sampler_name,
        )
        options.restoreFaces = data.restore_faces;
        options.samplingSteps = data.steps;
        options.cfg = data.cfg_scale;
        options.height = data.height;
        options.width = data.width;
        options.seed = data.seed;
        options.enableControlnet = data.enable_controlnet;
        options.module = data.module
        options.model = data.model;
        options.weight = data.weight;
        options.scribble = data.scribble_mode;
        options.resizeMode = data.resize_mode;
        options.rgbbgrMode = data.rgbbgr_mode;
        return options
    }


}