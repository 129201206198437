import { AIModel } from "@favo/models/ai.model.model";

export class Purchase {
    id: string;
    user_id: string;
    model_id: string;
    price: number;
    purchase_date: Date;
    fulfilled: boolean;
    failed: boolean;
    model: AIModel;

    constructor(id: string, user_id: string, model_id: string, price: number, purchase_date: Date, fulfilled: boolean, failed: boolean, model: AIModel) {
        this.id = id;
        this.user_id = user_id;
        this.model_id = model_id;
        this.price = price;
        this.purchase_date = purchase_date;
        this.fulfilled = fulfilled;
        this.failed = failed;
        this.model = model;
    }

}