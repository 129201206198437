import { AIModelService } from "@favo/services/ai.model.service";
import { DiffusionClientService } from "@favo/services/d.client.service";
import { PromptService } from "@favo/services/prompt.service";
import { PurchaseService } from "@favo/services/purchase.service";
import { UserService } from "@favo/services/user.service";
import { CredentialResponse } from "@react-oauth/google";
import React from "react";
import { Subject } from "rxjs";

export interface IGlobalContextType {
    loading: boolean;
    onSignedIn: Subject<CredentialResponse>;
    isLoggedIn: boolean;
    setIsLoggedIn: (isLoggedIn: boolean) => void;
    setLoader: (loading: boolean) => void;
    services: {
        userService: UserService;
        modelService: AIModelService;
        purchaseService: PurchaseService;
        diffusionClientService: DiffusionClientService;
        promptService: PromptService;
    }
}

export const GlobalContext = React.createContext<IGlobalContextType>({} as IGlobalContextType);