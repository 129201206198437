export class User {
    id: string;
    username: string;
    email: string;
    last_active: Date;
    stripe_id: string;
    created_at: Date;
    stripe_verified: boolean;
    free_tokens: number;
    purchased_tokens: number;
    subscribed: boolean;

    constructor(
        id: string, username: string, email: string, last_active: Date, stripe_id: string, created_at: Date, stripe_verified: boolean, free_tokens: number, purchased_tokens: number, subscribed: boolean) {
        this.id = id;
        this.username = username;
        this.email = email;
        this.last_active = last_active;
        this.stripe_id = stripe_id;
        this.created_at = created_at;
        this.stripe_verified = stripe_verified;
        this.free_tokens = free_tokens;
        this.purchased_tokens = purchased_tokens;
        this.subscribed = subscribed;
    }
}