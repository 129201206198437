import { AIModelView } from "@favo/models/ai.model.view.model";
import { Purchase } from "@favo/models/purchase.model";
import { Helper } from "@favo/utils/helper";
import axios from "axios";
import { Observable } from 'rxjs';

export class PurchaseService {


    public async connectStripe(): Promise<void> {
        const token = Helper.getToken();

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/stripe/connect`, {}, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        if (response.status === 200 && response.data !== "") {
            window.location = response.data.URL;
        }

    }

    public async createPaymentSession(modelId: string) {
        const token = Helper.getToken();

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/model/${modelId}/create/payment/session`, {}, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        if (response.status === 200 && response.data !== "") {
            window.location = response.data;
        }
    }

    public createPaymentFree(modelId: string): Observable<Purchase> {
        return new Observable<Purchase>(observer => {
            const token = Helper.getToken();

            axios.post(`${process.env.REACT_APP_API_URL}/model/${modelId}/create/payment/free`, {}, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {

                if (response.status === 200 && response.data != null) {

                    observer.next(new Purchase(
                        response.data.id,
                        response.data.user_id,
                        response.data.model_id,
                        response.data.price,
                        response.data.purchase_date,
                        response.data.fulfilled,
                        response.data.failed,
                        response.data.model
                    ));

                }

            }).catch(error => {
                observer.error(error);
                observer.complete();
            });

        });
    }

    public CheckIfModelPurchasedByCurrentUser(modelId: string): Observable<boolean | null> {
        return new Observable<boolean | null>(observer => {
            const token = Helper.getToken();

            axios.get(`${process.env.REACT_APP_API_URL}/user/model/${modelId}/owned`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {

                if (response.status === 200 && response.data != null) {

                    observer.next(response.data.status);

                } else {
                    observer.next(null);
                }

            }).catch(error => {
                observer.error(error);
                observer.complete();
            });

        });

    }

    /**
     *  Get user purchases from server
     * @returns Purchase[]
     */
    public getUserPurchases(): Observable<Purchase[]> {
        return new Observable<Purchase[]>(observer => {
            const token = Helper.getToken();

            axios.get(`${process.env.REACT_APP_API_URL}/user/purchases`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {

                if (response.status === 200 && response.data.length > 0) {
                    const purchases = response.data.map((purchase: any) => {
                        return new Purchase(
                            purchase.id,
                            purchase.user_id,
                            purchase.model_id,
                            purchase.price,
                            purchase.purchase_date,
                            purchase.fulfilled,
                            purchase.failed,
                            purchase.model
                        );
                    });

                    observer.next(purchases);
                    return;
                }

                observer.next([]);

            }).catch(error => {
                observer.error(error);
                observer.complete();
            });

        });
    }
    
    public getUserCheckpoints(): Observable<AIModelView[]> {
        return new Observable<AIModelView[]>(observer => {
            const token = Helper.getToken();

            axios.get(`${process.env.REACT_APP_API_URL}/user/checkpoints`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {

                if (response.status === 200 && response.data.length > 0) {
                    const checkpoints = response.data.map((r: any) => {
                        return new AIModelView(
                            r.id,
                            r.created_at,
                            r.updated_at,
                            r.title,
                            r.description,
                            r.author,
                            r.previews,
                            r.trigger_words,
                            r.type,
                            r.upvotes,
                            r.downvotes,
                            r.tags,
                            r.downloads,
                            r.version,
                            r.price,
                        );
                    });

                    observer.next(checkpoints);
                    return;
                }

                observer.next([]);

            }).catch(error => {
                observer.error(error);
                observer.complete();
            });

        });
    }

}