import { Comment } from '@favo/models/comment.model';

export class AIModel {

    id: string;
    title: string;
    description: string;
    author: string = "Anonymous";
    upVotes: number = 0;
    downVotes: number = 0;
    tags: string[] = [];
    totalDownloads: number = 0;
    totalViews: number = 0;
    totalComments: number = 0;
    thumbnails: string[] = [];
    price?: number = 0;
    comments: Comment[] = [];
    slug: string = "";

    constructor(id: string, title: string, description: string, author: string) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.author = author;
    }

    getThumbnails(): ModelThumbnail[] {
        let thumbnails: ModelThumbnail[] = [];
        this.thumbnails.forEach((thumbnail, index) => {
            thumbnails.push(new ModelThumbnail(thumbnail, index === 0));
        });
        return thumbnails;
    }

}

export class ModelThumbnail {
    url: string;
    isActivated: boolean;

    constructor(url: string, isActivated: boolean) {
        this.url = url;
        this.isActivated = isActivated;
    }
}