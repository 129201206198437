import DClient from '@favo/components/d-client/main';
import DetailModel from '@favo/components/detail/main';
import Home from '@favo/components/home/main';
import FavoLayout from '@favo/components/layout/main';
import BetaClosingLayout from './components/layout/beta-closing-layout';
import BetaClosing from './components/home/beta-closing';
import UserProfile from '@favo/components/user/profile/main';
import ModelUpload from '@favo/components/user/upload/main';
import { Route, Routes } from 'react-router-dom';
import './App.css';



function App() {
  return (
    <Routes>
      {/* <Route path='/' element={<FavoLayout />}> */}
      <Route path='/' element={<BetaClosingLayout />}>
        <Route index element={<BetaClosing />}></Route>
        {/* <Route path='/model/:slug' element={<DetailModel />}></Route> */}
        {/* <Route path='/model/upload' element={<ModelUpload />}></Route> */}
        {/* <Route path='/user/profile' element={<UserProfile />}></Route> */}
        {/* <Route path='/generate/:promptId?' element={<DClient />}></Route> */}
        {/* <Route path='*' element={<Home />}></Route> */}
      </Route>
    </Routes>
  );
}

export default App;
