export class CreateUploadUrls {
    upload_id: string;
    part_urls: string[];
    part_sizes: number[];

    constructor(upload_id: string, part_urls: string[], part_sizes: number[]) {
        this.upload_id = upload_id;
        this.part_urls = part_urls;
        this.part_sizes = part_sizes;
    }
}