import { AIModel } from "@favo/models/ai.model.model";
import { CreateModel } from "@favo/models/create.model";
import { CreateUploadUrls } from "@favo/models/create.upload.model";
import { Vote } from "@favo/models/vote.model";
import { Helper } from "@favo/utils/helper";
import axios from "axios";
import { Observable } from "rxjs";


export class AIModelService {



    public getModels(page: number): Observable<AIModel[]> {
        return new Observable<AIModel[]>(observer => {
            axios.get(`${process.env.REACT_APP_API_URL}/models?page=${page}`)
                .then(res => {
                    observer.next(res.data)
                }).catch(err => {
                    observer.error(err);
                });
        });
    }

    public getModel(modelId: string): Observable<AIModel> {
        return new Observable<AIModel>(observer => {
            axios.get(`${process.env.REACT_APP_API_URL}/model/${modelId}`)
                .then(res => {
                    const model = new AIModel(res.data.id, res.data.title, res.data.description, res.data.author);
                    model.thumbnails = res.data.thumbnails;
                    model.tags = res.data.tags;
                    model.upVotes = res.data.upVotes;
                    model.downVotes = res.data.downVotes;
                    model.totalDownloads = res.data.totalDownloads;
                    model.totalViews = res.data.totalViews;
                    model.totalComments = res.data.totalComments;
                    model.price = res.data.price;
                    model.comments = res.data.comments;
                    model.slug = res.data.slug;
                    observer.next(model);
                }).catch(err => {
                    observer.error(err);
                });
        });
    }

    public getModelBySlug(slug: string): Observable<AIModel> {
        return new Observable<AIModel>(observer => {
            axios.get(`${process.env.REACT_APP_API_URL}/model/slug/${slug}`)
                .then(res => {
                    const model = new AIModel(res.data.id, res.data.title, res.data.description, res.data.author);
                    model.thumbnails = res.data.thumbnails;
                    model.tags = res.data.tags;
                    model.upVotes = res.data.upVotes;
                    model.downVotes = res.data.downVotes;
                    model.totalDownloads = res.data.totalDownloads;
                    model.totalViews = res.data.totalViews;
                    model.totalComments = res.data.totalComments;
                    model.price = res.data.price;
                    model.comments = res.data.comments;
                    model.slug = res.data.slug;
                    observer.next(model);
                }).catch(err => {
                    observer.error(err);
                });
        });
    }

    public getCurrentUserModels(): Observable<AIModel[]> {
        const token = Helper.getToken();

        return new Observable<AIModel[]>(observer => {
            axios.get(`${process.env.REACT_APP_API_URL}/user/listings`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(res => {
                observer.next(res.data)
            }).catch(err => {
                observer.error(err);
            });
        });
    }

    public async createModel(data: CreateModel): Promise<boolean> {
        const token = Helper.getToken();

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/model/list`, data, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return response.status === 200 && response.data;
    }

    public async uploadModelImage(file: File): Promise<string> {
        const token = Helper.getToken();

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/media/upload`, {}, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        if (response.status === 200 && response.data && response.data.result) {

            const url = response.data.result.uploadURL;

            const formData = new FormData();
            formData.append("file", file);

            const uploadResponse = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            if (uploadResponse.status === 200 && uploadResponse.data.success && uploadResponse.data.success === true) {
                return uploadResponse.data.result.variants[0];
            }

        }

        return '';
    }

    public async createUploadModelUrls(fileSize: number, fileExt: string): Promise<CreateUploadUrls | null> {

        const token = Helper.getToken();

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/model/upload/create`, {
            file_size: fileSize,
            extension: fileExt
        }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        if (response.status === 200 && response.data) {

            const uploadId = response.data.upload_id;
            const partUrls = response.data.part_urls;
            const partSizes = response.data.part_sizes;

            // should have urls
            if (partUrls.length === 0) return null;

            return new CreateUploadUrls(uploadId, partUrls, partSizes);
        }

        return null;
    }

    public async createUploadComplete(uploadId: string, completed_parts: string[]): Promise<string[]> {

        const token = Helper.getToken();

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/model/upload/complete`, {
            upload_output: uploadId,
            completed_parts
        }, {
            headers: { "Authorization": `Bearer ${token}` }
        });

        if (response.status === 200) {
            return [response.data.Key, response.data.Bucket];
        }

        // abort if failed
        this.createUploadAbort(uploadId);

        return [];

    }

    public async createUploadAbort(uploadId: any): Promise<boolean> {
        const token = Helper.getToken();

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/model/upload/abort`, uploadId, {
            headers: { "Authorization": `Bearer ${token}` }
        });

        if (response.status === 200) return true;

        return false;
    }

    public async voteModel(modelId: string, isUpVote: boolean): Promise<boolean> {
        const token = Helper.getToken();

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/model/vote`, {
            model_id: modelId,
            is_upvote: isUpVote
        }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return response.status === 200 && response.data;
    }

    public async getModelVotes(modelId: string): Promise<Vote | null> {
        const token = Helper.getToken();

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/model/${modelId}/votes`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        if (response.status === 200 && response.data) {
            return new Vote(response.data.upvotes, response.data.downvotes);
        }

        return null;
    }

    public async getModelCommentVotes(commentId: string): Promise<Vote | null> {
        const token = Helper.getToken();

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/model/comments/${commentId}/votes`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        if (response.status === 200 && response.data) {
            return new Vote(response.data.upvotes, response.data.downvotes);
        }

        return null;
    }

    async voteModelComment(commentId: string, isUpVote: boolean) {
        const token = Helper.getToken();

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/model/comment/vote`, {
            comment_id: commentId,
            is_upvote: isUpVote
        }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return response.status === 200 && response.data;
    }

    public async createComment(modelId: string, commentText: string, images: string[], parent_id: string | null): Promise<boolean> {
        const token = Helper.getToken();

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/model/comment/create`, {
            model_id: modelId,
            content: commentText,
            images: images.join(','),
            parent_id: parent_id
        }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        if (response.status === 200 && response.data.created) {
            return true;
        }

        return false;
    }

    // delete comment
    public async deleteComment(commentId: string): Promise<boolean> {

        const token = Helper.getToken();

        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/model/comment/${commentId}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        if (response.status === 200 && response.data.deleted) {
            return true;
        }

        return false;

    }





}