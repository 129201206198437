export class Sampler {
    name: string;
    aliases: string[];
    options: any;

    constructor(name: string, aliases: string[], options: any) {
        this.name = name;
        this.aliases = aliases;
        this.options = options;
    }
}