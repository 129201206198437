export class AIModelView {
    id: string;
    created_at: string;
    updated_at: string;
    title: string;
    description: string;
    author: string = "Anonymous";
    previews: string[];
    trigger_words: string[];
    type: string;
    upvotes: number = 0;
    downvotes: number = 0;
    tags: string[] = [];
    downloads: number = 0;
    version: string;
    price?: number = 0;

    constructor(
        id: string,
        created_at: string,
        updated_at: string,
        title: string,
        description: string,
        author: string = "Anonymous",
        previews: string[],
        trigger_words: string[],
        type: string,
        upvotes: number = 0,
        downvotes: number = 0,
        tags: string[] = [],
        downloads: number = 0,
        version: string,
        price: number = 0,
      ) {
        this.id = id;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.title = title;
        this.description = description;
        this.author = author;
        this.previews = previews;
        this.trigger_words = trigger_words;
        this.type = type;
        this.upvotes = upvotes;
        this.downvotes = downvotes;
        this.tags = tags;
        this.downloads = downloads;
        this.version = version;
        if (price) {
          this.price = price;
        }
    }
}
