export class Helper {

    public static getToken(): string | undefined {
        const cookie = document.cookie;
        const cookies = cookie.split(';');
        for (const c of cookies) {
            const parts = c.split('=');
            const key = parts[0].trim();
            if (key === 'token') {
                return parts[1];
            }
        }
        return undefined;
    }

    public static getTokenExpiry(): string | undefined {
        const cookie = document.cookie;
        const cookies = cookie.split(';');
        for (const c of cookies) {
            const parts = c.split('=');
            const key = parts[0].trim();
            if (key === 'token_expiry') {
                return parts[1];
            }
        }
        return undefined;
    }

}